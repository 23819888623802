import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface ScreenSpinnerProps {
  /**
   * Optional custom size for the spinner (default: '3x')
   */
  size?:
    | "xs"
    | "sm"
    | "lg"
    | "1x"
    | "2x"
    | "3x"
    | "4x"
    | "5x"
    | "6x"
    | "7x"
    | "8x"
    | "9x"
    | "10x";

  /**
   * Optional custom color for the spinner (default: '#3498db')
   */
  color?: string;

  /**
   * Optional custom text to display below the spinner
   */
  text?: string;

  /**
   * Optional flag to control the full-screen overlay (default: true)
   */
  fullScreen?: boolean;
}

/**
 * ScreenSpinner component that shows a loading animation
 * over the full screen or as an inline element
 */
const ScreenSpinner: React.FC<ScreenSpinnerProps> = ({
  size = "3x",
  color = "#3498db",
  text,
  fullScreen = true,
}) => {
  const spinnerStyles: React.CSSProperties = {
    color: color,
  };

  const containerStyles: React.CSSProperties = fullScreen
    ? {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        zIndex: 9999,
      }
    : {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
      };

  const textStyles: React.CSSProperties = {
    marginTop: "10px",
    color: color,
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <FontAwesomeIcon
        icon={faSpinner}
        spin
        size={size}
        style={spinnerStyles}
        aria-label="Loading"
      />
      {text && <div style={textStyles}>{text}</div>}
    </div>
  );
};

export default ScreenSpinner;
